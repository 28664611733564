import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { TextField } from '@mui/material';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import SEO from '../components/SEO';
import ContactForm from '../components/ContactForm';

function CCPARequest({ siteConfig }) {
  const [request, setRequest] = useState({});
  const [requestedData, setRequestedData] = useState({});

  useEffect(() => {
    setRequest({
      ...request,
      ['Requested Data']: Object.keys(requestedData)
        .filter((key) => requestedData[key])
        .join(', ')
    });
  }, [requestedData]);

  useEffect(() => {
    console.log('request', request);
  }, [request]);

  return (
    <>
      <SEO
        title={'California Consumer Privacy Act Request Form'}
        description={
          'California residents can use this form to submit requests under the California Consumer Privacy Act (CCPA) regarding personal information collected by ' +
          siteConfig.brand +
          '.'
        }
      />
      <ContactForm
        title="California Consumer Privacy Act Request Form"
        subject="CCPA Request"
        request={request}
        setRequest={setRequest}
        intro={
          <>
            <p>
              California residents can use this form to submit requests under
              the California Consumer Privacy Act (CCPA) regarding personal
              information collected by {siteConfig.brand}.{' '}
            </p>
            <p>
              For certain requests, we may ask for additional information or
              documents to verify the identity of the consumer who is the
              subject of the request. The information provided through this form
              will be used to respond to your request, including verifying
              identity, identifying personal information responsive to your
              request, and keeping records of your request.
            </p>
            <p>
              {siteConfig.brand} reserves the right to refuse requests, in part
              or in whole, to the extent permitted by law, if we are unable to
              verify your identity, or if we cannot verify your authority to act
              on behalf of another person.
            </p>
            <p>
              For more information on your rights under the CCPA, please see our{' '}
              <a href="/privacy-policy" className="link">
                privacy policy
              </a>
              .
            </p>
          </>
        }
        fields={
          <>
            <TextField
              variant="outlined"
              label="Street Address"
              required
              fullWidth
              value={request['Street Address']}
              onChange={(event) =>
                setRequest({
                  ...request,
                  ['Street Address']: event.target.value
                })
              }
            />

            <TextField
              variant="outlined"
              required
              label="City"
              fullWidth
              value={request['City']}
              onChange={(event) =>
                setRequest({ ...request, ['City']: event.target.value })
              }
            />

            <TextField
              variant="outlined"
              required
              label="State"
              fullWidth
              value={request['State']}
              onChange={(event) =>
                setRequest({ ...request, ['State']: event.target.value })
              }
            />

            <TextField
              variant="outlined"
              required
              label="Zip"
              fullWidth
              value={request['Zip']}
              onChange={(event) =>
                setRequest({ ...request, ['Zip']: event.target.value })
              }
            />

            <FormGroup>
              <FormLabel component="legend">Select all that apply: *</FormLabel>
              <FormControlLabel
                control={<Checkbox />}
                label={'Request for disclosure (tell me about my data)'}
                onChange={(event) =>
                  setRequestedData({
                    ...requestedData,
                    ['Request for disclosure (tell me about my data)']:
                      event.target.checked
                  })
                }
              />
              <FormControlLabel
                control={<Checkbox />}
                label={'Request for access (give me my data)'}
                onChange={(event) =>
                  setRequestedData({
                    ...requestedData,
                    ['Request for access (give me my data)']:
                      event.target.checked
                  })
                }
              />
              <FormControlLabel
                control={<Checkbox />}
                label={'Request for deletion (delete my data)'}
                onChange={(event) =>
                  setRequestedData({
                    ...requestedData,
                    ['Request for deletion (delete my data)']:
                      event.target.checked
                  })
                }
              />
            </FormGroup>

            <FormControl component="fieldset" className="radio">
              <FormLabel component="legend">
                Please indicate how you would like to receive the information
                requested above by checking one of the following boxes: *
              </FormLabel>
              <RadioGroup
                name="radio-buttons-group"
                onChange={(event) =>
                  setRequest({
                    ...request,
                    ['Method of Receipt']: event.target.value
                  })
                }>
                <FormControlLabel
                  value="Email"
                  control={<Radio />}
                  label="By Email (at the Email above)"
                />
                <FormControlLabel
                  value="Mail"
                  control={<Radio />}
                  label="By Mail (at Address above)"
                />
              </RadioGroup>
            </FormControl>

            <FormControl component="fieldset" className="radio">
              <FormLabel component="legend">
                Please read and select the applicable option below: *
              </FormLabel>
              <RadioGroup
                name="radio-buttons-group"
                onChange={(event) =>
                  setRequest({
                    ...request,
                    ['Requestor']: event.target.value
                  })
                }>
                <FormControlLabel
                  value="Individual"
                  control={<Radio />}
                  label="I declare under penalty of perjury that I am the individual consumer whose personal information is the subject of the request."
                />
                <FormControlLabel
                  value="Authorized Agent"
                  control={<Radio />}
                  label="I declare under penalty of perjury that I am the Authorized Agent of the individual whose personal information is the subject of the request. I understand I will be required to provide proof in writing of my status as the individual's Authorized Agent and additional information to confirm my identity."
                />
              </RadioGroup>
            </FormControl>
          </>
        }
        requiredFieldList={[
          'Street Address',
          'City',
          'State',
          'Zip',
          'Requested Data',
          'Method of Receipt',
          'Requestor'
        ]}
      />
    </>
  );
}

function mapStateToProps(state) {
  return {
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {}
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CCPARequest);
